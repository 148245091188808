
import { useAddInfo_GetFile } from '@/composables/useAdditionalReport';
import { FetchStates } from '@/helpers/fetch-states';
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
    name: 'AsyncImage',
    props: {
        data: {
            type: Object,
            required: true,
        },
        report_id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const fetchState = ref<FetchStates>(FetchStates.FETCHING);
        const isImage = ref<boolean>(false);
        const fileUrl = ref<string | null>(null);
        const error = ref<string | null>(null);
        const fileName = ref<string>('Unknown');
        const fetchImage = async () => {
            if ( props.data == null) {

                fetchState.value = FetchStates.FETCHED;
                return;
            }
            const blob = await useAddInfo_GetFile(props.report_id, props.data.key);
            fileUrl.value = URL.createObjectURL(blob);
            fileName.value = props.data.name;
            isImage.value = props.data.mime.startsWith('image');
            fetchState.value = FetchStates.FETCHED;
        }
        onMounted(async () => {
            try {
                await fetchImage();
            } catch (e) {
                error.value = "File not Found";
                fetchState.value = FetchStates.FETCHED;
            }
        });
        return {
            fetchState,
            fetchImage,
            isImage,
            fileUrl,
            FetchStates,
            error,
            fileName
        };
    },
    watch: {
        data: {
            handler: function () {
                this.fetchState = FetchStates.FETCHING;
                this.fetchImage();
            },
            deep: true,
        },
    },
});
