
import { defineComponent, onMounted, ref } from 'vue';
import { RBRecordDTO } from '@/api/dto/report';
import { reportProps } from '@/helpers/report-comp-props';
import { UserPrefsModule } from '@/store/userprefs';
import { AddInfoRecord } from '@/api/dto/add-info.dto';
import { alertError } from '@/helpers/alert-error';
import {
  useAddInfo_AddRecord,
  useAddInfo_RemoveRecord,
  useAddInfo_UpdateRecord
} from '@/composables/useAdditionalReport';
import { ElFile, UploadFile } from 'element-plus/lib/el-upload/src/upload.type';
import { convertDateString } from '@/helpers/date-format';
import { FetchStates } from '@/helpers/fetch-states';
import AsyncImage from '@/components/AsyncImage.vue';

export default defineComponent({
  name: 'AdditionalInformationReport',
  props: {
    ...reportProps,
  },
  components: {
    AsyncImage,
  },
  setup(props) {
    const userPrefs = ref(null);
    const fetchState = ref<FetchStates>(FetchStates.FETCHED);
    fetchState.value = FetchStates.FETCHED;
    const uploadRef = ref<{
      abort: (file: UploadFile) => void;
      uploadFiles: UploadFile[];
      upload: (file: ElFile) => void;
    }>(null);
    const records = ref<RBRecordDTO[]>(props.records);
    const dialogVisible = ref(false);
    const changeDialog = ref(false);
    const fileList = ref<UploadFile[]>([]);
    const newRecord = ref<AddInfoRecord>({
      name: '',
      note: '',
      value: null,
    });
    const changeRecord = ref<AddInfoRecord>({
      name: '',
      note: '',
      value: null,
    });
    onMounted(async () => {
      userPrefs.value = await UserPrefsModule.getUserPrefs();
    });
    return {
      userPrefs,
      records,
      dialogVisible,
      uploadRef,
      newRecord,
      fileList,
      convertDateString,
      fetchState,
      FetchStates,
      changeDialog,
      changeRecord,
    };
  },
  computed: {},
  methods: {
    fileSelected() {
      if (this.uploadRef != null) {
        return this.uploadRef.uploadFiles.length == 0;
      }
      return true;
    },
    async showDialogFor(id: string, record: AddInfoRecord) {
      this.changeRecord = record;
      this.changeRecord._id = id;
      this.changeDialog = true;
    },
    async changeNote() {
      console.log(this.changeRecord);
      this.fetchState = FetchStates.FETCHING;
      await useAddInfo_UpdateRecord(
        this.report._id,
        this.changeRecord._id,
        this.changeRecord,
      );
      this.changeDialog = false;
      this.fetchState = FetchStates.FETCHED;
    },
    async deleteRecord(id: string) {
      this.fetchState = FetchStates.FETCHING;
      await useAddInfo_RemoveRecord(this.report._id, id);
      this.records = this.records.filter(record => record._id != id);
      this.fetchState = FetchStates.FETCHED;
    },
    async addNewRecord() {
      if (this.newRecord.name == '') {
        alertError('', 'Please enter a name for the record.');
        return;
      }
      this.fetchState = FetchStates.FETCHING;
      this.dialogVisible = false;
      if (this.uploadRef.uploadFiles.length > 0) {
        this.newRecord.value = this.uploadRef.uploadFiles[0].raw;
      }
      const record = await useAddInfo_AddRecord(
        this.report._id,
        this.newRecord,
      );
      this.records.push({
        timestamp: Date.now(),
        data: record.data,
        _id: record.record_id,
      });
      this.uploadRef.uploadFiles = [];
      this.newRecord = {
        name: '',
        note: '',
        value: null,
      };
      this.fetchState = FetchStates.FETCHED;
    },
  },
});
