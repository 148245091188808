import { AddInfoRecord } from '@/api/dto/add-info.dto';
import {
  AddInfo_addImageToRecord,
  AddInfo_addRecord,
  AddInfo_removeRecord,
  AddInfo_getFile,
  AddInfo_updateRecord,
} from '@/api/report-builder';

export async function useAddInfo_AddRecord(
  report_id: string,
  record: AddInfoRecord,
) {
  const record_id = await AddInfo_addRecord(report_id, record);
  if (!record.value) {
    return {
      record_id: record_id,
      data: { name: record.name, note: record.note, file: null },
    };
  }
  const _record = await AddInfo_addImageToRecord(
    report_id,
    record_id,
    record.value,
  );
  return {
    record_id: record_id,
    data: _record,
  };
}
export async function useAddInfo_UpdateRecord(
  report_id: string,
  record_id: string,
  record: AddInfoRecord,
) {
  await AddInfo_updateRecord(report_id, record_id, record);
  return {
    record_id: record_id,
    data: record,
  };
}

export async function useAddInfo_RemoveRecord(
  report_id: string,
  record_id: string,
) {
  await AddInfo_removeRecord(report_id, record_id);
}

export async function useAddInfo_GetFile(report_id: string, file_key: string) {
  return await AddInfo_getFile(report_id, file_key);
}
